<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            :range="true"
            defaultStart="-2d"
            defaultEnd="6d"
            label="발행일"
            name="requestDts"
            v-model="searchParam.requestDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="발행부서" name="reqDeptCd" v-model="searchParam.reqDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="작업부서" name="workDeptCd" v-model="searchParam.workDeptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="WO_WORK_REQ_STEP_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="woWorkReqStepCd"
            label="진행상태"
            v-model="searchParam.woWorkReqStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.woWorkReqStepCd)]">
                    {{ props.row.woWorkReqStepName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.woRequestName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.woRequestDt }} | {{ props.row.userName }} | {{ props.row.woWorkTypeName }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-order-request',
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkReqStepCd: null,
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'woWorkReqStepName',
            field: 'woWorkReqStepName',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            fix: true,
            name: 'woRequestName',
            field: 'woRequestName',
            label: '작업명',
            align: 'left',
            sortable: true,
            style: 'width:350px',
            type: 'link'
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비Tag',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '발행부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'deptNames',
            field: 'deptNames',
            label: '작업부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업유형',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woRequestDt',
            field: 'woRequestDt',
            label: '발행일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workreq.list.url;
      this.getList();
    },
    getColorStatus(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWRSC00001': // 작성중
          returnColor = 'txt-box-grid text-orange-box';
          break;
        case 'WWRSC00002': // 승인요청중
          returnColor = 'txt-box-grid text-primary-box';
          break;
        case 'WWRSC00003': // 승인완료
          returnColor = 'txt-box-grid text-positive-box';
          break;
        case 'WWRSC00009': // 접수 반려
          returnColor = 'txt-box-grid text-red-box';
          break;
        case 'WWRSC00010': // 접수 승인
          returnColor = 'txt-box-grid text-primary-box';
          break;
      }

      return returnColor;
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(evt, row) {
      this.popupOptions.title = '작업요청 상세';
      this.popupOptions.param = {
        woRequestId: row ? row.woRequestId : '',
        plantCd: row ? row.plantCd : this.searchParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'./workOrderRequestDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
